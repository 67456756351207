import React from "react";
import { Fade } from "react-awesome-reveal";
import Copyright from "./Copyright";
import { FiMail, FiMapPin, FiPhone, FiPrinter } from "react-icons/fi";

interface FooterProps {
  variant?: string;
}

const Footer: React.FC<FooterProps> = ({ variant }) => {
  const renderAbout = () => (
    <Fade triggerOnce>
      <div>
        <a href="/" className="flex items-center gap-3 mb-4">
          <img src="/images/logo2.png" alt="Logo" className="w-16" />
          <div>
            <p
              className={`${variant === "light" ? "" : "text-white"
                } text-2xl font-bold`}
            >
              DELTA
            </p>
            <p
              className={`${variant === "light" ? "" : "text-white"
                } text-md font-semibold mb-0`}
            >
              MORTGAGES INC.
            </p>
          </div>
        </a>
        <p
          className={`${variant === "light" ? "text-gray-600" : "text-gray-300"
            } mb-4`}
        >
          We, at Delta Mortgages Inc., will have the way with the right mortgage
          for you
        </p>
      </div>
    </Fade>
  );

  const renderQuickLinks = () => (
    <Fade delay={200} triggerOnce>
      <div className="pl-0 lg:pl-10">
        <h3 className="text-lg mb-4">Quick Links</h3>
        <ul>
          <li className="mb-2">
            <a
              href="/about"
              className={`${variant === "light"
                ? "text-gray-600 hover:text-black"
                : "text-gray-300 hover:text-white"
                }`}
            >
              About
            </a>
          </li>
          <li className="mb-2">
            <a
              href="/news"
              className={`${variant === "light"
                ? "text-gray-600 hover:text-black"
                : "text-gray-300 hover:text-white"
                }`}
            >
              News
            </a>
          </li>
          <li className="mb-2">
            <a
              href="/blogs"
              className={`${variant === "light"
                ? "text-gray-600 hover:text-black"
                : "text-gray-300 hover:text-white"
                }`}
            >
              Blogs
            </a>
          </li>
          <li className="mb-2">
            <a
              href="/contact"
              className={`${variant === "light"
                ? "text-gray-600 hover:text-black"
                : "text-gray-300 hover:text-white"
                }`}
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </Fade>
  );

  const renderContactUs = () => (
    <Fade delay={400} triggerOnce>
      <div>
        <h3 className="text-lg mb-4">Contact Us</h3>
        <div className="flex items-center gap-3 mb-4">
          <div className="flex items-center">
            <FiMapPin size={20} className="text-cred-100" />
          </div>
          <p
            className={`${variant === "light"
              ? "text-gray-600 hover:text-black"
              : "text-gray-300 hover:text-white"
              }`}
          >
            235 Queen St E, Brampton,ON, L6W 2B5
          </p>
        </div>
        <div className="flex items-center gap-3 mb-4">
          <a
            href="tel:905-216-0061"
            className={`flex gap-2 items-center ${variant === "light"
              ? "text-gray-600 hover:text-black"
              : "text-gray-300 hover:text-white"
              }`}
          >
            <FiPhone size={20} className="text-cred-100" />
            <span>905-216-0061</span>
          </a>
        </div>
        <div className="flex gap-3 mb-4">
          <a
            href="tel:289-752-6194"
            className={`flex gap-2 items-center ${variant === "light"
              ? "text-gray-600 hover:text-black"
              : "text-gray-300 hover:text-white"
              }`}
          >
            <FiPrinter size={20} className="text-cred-100" />
            <span>289-752-6194</span>
          </a>
        </div>
        <div className="flex items-center gap-3">
          <a
            href="mailto:admin@deltamo.ca"
            className={`flex gap-2 items-center ${variant === "light"
              ? "text-gray-600 hover:text-black"
              : "text-gray-300 hover:text-white"
              }`}
          >
            <FiMail size={20} className="text-cred-100" />
            <span>admin@deltamo.ca</span>
          </a>
        </div>
      </div>
    </Fade>
  );

  const renderCopyright = () => (
    <Fade triggerOnce>
      <Copyright variant={variant} />
    </Fade>
  );

  return (
    <footer
      className={`${variant === "light" ? "" : "bg-neutral-900 text-white"}`}
    >
      <div className="container mx-auto px-6 py-10 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {renderAbout()}
          {renderQuickLinks()}
          {renderContactUs()}
        </div>
        {renderCopyright()}
      </div>
    </footer>
  );
};

export default Footer;
